<template>
  <div class="text-center">
    <div class="text-lg font-semibold text-warning font-montserrat pb-1">
      {{ title }}
    </div>
    <div class="text-medium font-base text-secondary-700 font-montserrat">
      {{ description }}
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
      default: () => '',
    },
    description: {
      type: String,
      required: true,
      default: () => '',
    },
  },
})
</script>
