<template>
  <custom-select
    v-model="value"
    :options="portals"
    :transform="transform"
    class="flex-1 max-w-xs"
    itemsClassName="bg-secondary-100 rounded-bl rounded-br border-l border-r border-b border-secondary-500"
  >
    <template v-slot="{ option, active, selection, open }">
      <div
        class="
          text-lg
          flex
          items-center
          font-bold font-montserrat
          text-secondary-700
          cursor-pointer
        "
        :class="{
          'hover:text-primary ': !selection,
          'border border-secondary-500': selection,
          'rounded ': selection & !open,
          'rounded-tl rounded-tr': selection && open,
          'p-2': selection,
          'px-2 py-4': !selection,
        }"
      >
        <div
          class="
            w-8
            h-8
            rounded
            bg-primary
            mr-2
            flex
            items-center
            justify-center
            text-secondary-100
          "
          v-if="option"
        >
          {{ initials(option) }}
        </div>
        <div class="flex-1" :class="{ 'text-primary': active }" v-if="option">
          {{ option.name }}
        </div>
        <div class="flex-1" :class="{ 'text-primary': active }" v-if="!option">
          {{ $t('projects.choosePortal') }}
        </div>
        <icon
          :icon="checkmarkOutline"
          class="rn-check text-xl px-1 text-primary"
          v-if="active"
        />
        <icon
          :icon="caretDownOutline"
          class="text-xl px-1 text-secondary-500"
          v-if="selection"
        />
      </div>
    </template>
  </custom-select>
</template>

<script>
import { defineComponent } from 'vue'
import { mapMutations } from 'vuex'
import { checkmarkOutline, caretDownOutline } from 'ionicons/icons'
import CustomSelect from '@/components/molecules/CustomSelect'
import { initials } from '@/utils/portal'
import Icon from '@/components/atoms/Icon'

export default defineComponent({
  name: 'PortalSelect',
  components: {
    CustomSelect,
    Icon,
  },
  props: {
    portals: {
      type: Array,
      default: () => [],
    },
    modelValue: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      checkmarkOutline,
      caretDownOutline,
    }
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(val) {
        this.setQuery('')
        this.$emit('update:modelValue', val)
        this.$emit('input', val)
      },
    },
  },
  methods: {
    transform(portal) {
      return {
        value: portal.id,
        label: portal.name,
      }
    },
    initials,
    ...mapMutations(['setQuery']),
  },
})
</script>

<style scoped>
.rn-check {
  /* https://ionic.io/ionicons/usage */
  --ionicon-stroke-width: 70px;
}
</style>
