<template>
  <slot name="small" v-if="!isMd" />
  <slot name="large" v-if="isMd" />
</template>

<script>
import { defineComponent } from 'vue'

import { isMediumSize } from '@/utils/responsive'

export default defineComponent({
  data() {
    return {
      isMd: isMediumSize(),
    }
  },
  created() {
    window.addEventListener('resize', this.windowResized)
  },
  unmounted() {
    window.removeEventListener('resize', this.windowResized)
  },
  methods: {
    windowResized() {
      this.isMd = isMediumSize()
    },
  },
})
</script>
