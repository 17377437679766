<template>
  <div
    class="
      bg-secondary-400
      rounded
      relative
      flex-1 flex flex-row
      items-center
      md:w-60
    "
  >
    <icon-button
      :icon="closeCircleOutline"
      class="p-2.5 flex-initial md:hidden text-secondary-700"
      size="medium"
      @click="cleanQuery"
      v-if="query"
    />
    <icon
      :icon="searchOutline"
      size="medium"
      class="text-secondary-500 flex-initial p-1.5 block md:hidden"
      v-if="!query"
    />
    <icon
      :icon="searchOutline"
      size="medium"
      class="text-secondary-500 flex-initial p-1.5 hidden md:block"
    />
    <div class="flex-1 relative">
      <input
        type="text"
        class="
          w-full
          relative
          outline-none
          bg-transparent
          font-montserrat font-semibold
          text-lg
          py-2.5
          text-secondary-700
        "
        :placeholder="$t('projects.searchProject')"
        v-model="query"
        @input="changeQuery"
      />
    </div>

    <icon-button
      :icon="optionsOutline"
      size="medium"
      class="text-secondary-700 p-2.5 flex-initial md:hidden"
      @click="listSettingsOpen = true"
    />
    <icon-button
      :icon="closeCircleOutline"
      size="medium"
      class="text-secondary-700 p-2.5 flex-initial hidden md:block"
      @click="cleanQuery"
      v-if="query"
      :title="$t('projects.cleanQuery')"
    />
  </div>
  <responsive-renderer>
    <template v-slot:small>
      <bottom-sheet :open="listSettingsOpen" @close="listSettingsOpen = false">
        <section-item>
          <section-title>
            {{ $t('projects.layout.layout') }}
          </section-title>
          <div
            v-for="option in layoutOptions"
            :key="option.value"
            class="
              text-lg
              py-2
              flex
              items-center
              font-bold font-montserrat
              cursor-pointer
              hover:text-primary
            "
            :class="{
              'text-primary': option.value === projectsLayout,
              'text-secondary-700': option.value !== projectsLayout,
            }"
            @click="setProjectsLayout(option.value), (listSettingsOpen = false)"
          >
            <div class="w-8 h-8 mr-2 flex items-center justify-center text-xl">
              <icon :icon="option.icon" />
            </div>
            <div class="flex-1">
              {{ $t(option.label) }}
            </div>
            <icon
              :icon="checkmarkOutline"
              class="rn-check text-xl px-1"
              v-if="option.value === projectsLayout"
            />
          </div>
        </section-item>
      </bottom-sheet>
    </template>
    <template v-slot:large>
      <el-dropdown
        placement="bottom-end"
        @command="setProjectsLayout"
        trigger="click"
      >
        <div class="ml-2 cursor-pointer">
          <icon-button
            :icon="optionsOutline"
            class="hidden md:block"
            size="medium"
            primary
            :title="$t('projects.layout.layout')"
          />
        </div>

        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item
              :command="layout.value"
              :key="layout.value"
              v-for="layout in layoutOptions"
            >
              <div
                class="flex items-center"
                :class="{ 'text-primary': layout.value === projectsLayout }"
              >
                <icon :icon="layout.icon" class="mr-3 text-xl" />
                {{ $t(layout.label) }}
                <icon
                  :icon="checkmarkOutline"
                  class="text-xl pl-6"
                  v-if="layout.value === projectsLayout"
                />
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </template>
  </responsive-renderer>
</template>

<script>
import { defineComponent } from 'vue'
import { ElDropdown, ElDropdownMenu, ElDropdownItem } from 'element-plus'

import {
  optionsOutline,
  closeCircleOutline,
  searchOutline,
  checkmarkOutline,
  gridOutline,
  listOutline,
} from 'ionicons/icons'
import { mapGetters, mapMutations } from 'vuex'

import BottomSheet from '@/components/atoms/BottomSheet'
import SectionTitle from '@/components/atoms/SectionTitle'
import SectionItem from '@/components/atoms/SectionItem'
import IconButton from '@/components/atoms/IconButton'
import Icon from '@/components/atoms/Icon'
import ResponsiveRenderer from '@/components/atoms/ResponsiveRenderer'

export default defineComponent({
  name: 'Projects',
  components: {
    BottomSheet,
    SectionTitle,
    IconButton,
    Icon,
    SectionItem,
    ElDropdown,
    ElDropdownMenu,
    ElDropdownItem,
    ResponsiveRenderer,
  },
  props: {
    projects: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    return {
      checkmarkOutline,
      optionsOutline,
      closeCircleOutline,
      searchOutline,
      layoutOptions: [
        { value: 'grid', label: 'projects.layout.grid', icon: gridOutline },
        { value: 'list', label: 'projects.layout.list', icon: listOutline },
      ],
    }
  },
  data() {
    return {
      listSettingsOpen: false,
    }
  },
  computed: {
    ...mapGetters(['projectsLayout', 'query']),
  },
  methods: {
    changeQuery(event) {
      const value = event.target.value
      this.setQuery(value)
    },
    cleanQuery() {
      this.query = ''
      this.setQuery('')
    },
    ...mapMutations(['setProjectsLayout', 'setQuery']),
  },
})
</script>

<style scoped>
.rn-icon {
  /* https://ionic.io/ionicons/usage */
  --ionicon-stroke-width: 40px;
}

.rn-check {
  /* https://ionic.io/ionicons/usage */
  --ionicon-stroke-width: 70px;
}

/* https://stackoverflow.com/questions/66484943/how-to-change-title-of-an-ion-icon */
.rn-ion-icon {
  pointer-events: none;
}
</style>
