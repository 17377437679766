<template>
  <div
    class="relative outline-none"
    :class="className"
    :tabindex="tabindex"
    @blur="open = false"
  >
    <div class="w-full" @click="open = !open">
      <slot
        v-bind:option="selectedOption"
        v-bind:open="open"
        v-bind:active="false"
        v-bind:selection="true"
      >
        {{ selectedOption.label }}
      </slot>
    </div>
    <responsive-renderer>
      <template v-slot:small>
        <bottom-sheet :open="open" @close="open = false">
          <section-item>
            <div
              v-for="option of options"
              :key="transform(option).value"
              @click="onChange(transform(option).value)"
              class="w-full space-y-10"
            >
              <slot
                v-bind:option="option"
                v-bind:active="transform(option).value === modelValue"
                v-bind:selection="false"
              >
                {{ transform(option).label }}
              </slot>
            </div>
          </section-item>
        </bottom-sheet>
      </template>
      <template v-slot:large>
        <div
          class="overflow-hidden absolute inset-x-0 z-50"
          :class="[{ hidden: !open }, itemsClassName]"
        >
          <div
            v-for="option of options"
            :key="transform(option).value"
            @click="onChange(transform(option).value)"
            class="w-full"
          >
            <slot
              v-bind:option="option"
              v-bind:active="transform(option).value === modelValue"
              v-bind:selection="false"
            >
              {{ transform(option).label }}
            </slot>
          </div>
        </div>
      </template>
    </responsive-renderer>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

import BottomSheet from '@/components/atoms/BottomSheet'
import SectionItem from '@/components/atoms/SectionItem'
import ResponsiveRenderer from '@/components/atoms/ResponsiveRenderer'

export default defineComponent({
  name: 'CustomSelect',
  components: {
    BottomSheet,
    SectionItem,
    ResponsiveRenderer,
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: 'Choose',
    },
    class: {
      type: String,
    },
    modelValue: {
      type: String,
      default: '',
    },
    transform: {
      type: Function,
      default: foo => foo,
    },
    tabindex: {
      type: Number,
      default: 0,
    },
    itemsClassName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      open: false,
    }
  },
  computed: {
    className: function () {
      return [this.class]
    },
    selectedOption: {
      get() {
        return this.options.find(
          o => this.transform(o).value === this.modelValue
        )
      },
    },
  },
  methods: {
    onChange(value) {
      this.open = false
      this.$emit('update:modelValue', value)
      this.$emit('input', value)
    },
  },
})
</script>
