<template>
  <div
    class="rn-container cursor-pointer shadow-card rounded"
    :class="{ 'flex flex-row items-center mb-2': layout === 'list' }"
    @click="onClick"
  >
    <div
      class="
        rn-container-image
        bg-secondary-500
        overflow-hidden
        relative
        flex-none
      "
      :class="{
        'h-half md:h-32 rounded-t': layout === 'grid',
        'w-14 h-14 md:w-16 md:h-16 rounded-l': layout === 'list',
      }"
    >
      <img
        :src="imageUrl"
        class="rn-image absolute inset-0 w-full h-full object-cover"
        @error="onImageUrlError($event)"
      />
    </div>
    <div
      :class="{
        'px-1.5': layout === 'list',
        ' w-full px-2 pb-2 pt-1': layout === 'grid',
      }"
      class="flex-1"
    >
      <project-label
        :project="project"
        :offline-status="offlineStatus"
        :layout="layout"
      >
        <button
          class="flex-0 bg-primary text-white px-1 py-0.5 rounded"
          :class="{ 'opacity-50': requestDisabled }"
          @click="requestAccess"
          v-if="shouldDisplayAskAccess"
          :disabled="requestDisabled"
        >
          {{ $t(labelRequest) }}
        </button>
      </project-label>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

import {
  getProjectCoverUrl,
  getProjectLargeCoverUrl,
  getProjectLargeCoverUrlError,
  getProjectCoverUrlError,
} from '@/utils/links'
import { hasAccessToProject, ProjectAccess } from '@/utils/project'
import ProjectLabel from '@/components/molecules/ProjectLabel'
import { mapGetters } from 'vuex'

export default defineComponent({
  components: {
    ProjectLabel,
  },
  props: {
    project: {
      type: Object,
    },
    layout: {
      type: String,
      default: 'grid',
    },
  },
  emits: ['click'],
  computed: {
    imageUrl() {
      return this.layout === 'grid'
        ? getProjectLargeCoverUrl(this.project)
        : getProjectCoverUrl(this.project)
    },
    shouldDisplayAskAccess() {
      const portal = this.$store.getters.getPortalById(this.project?.portalId)
      return !hasAccessToProject(this.project, portal)
    },
    labelRequest() {
      if (this.project.access.status === ProjectAccess.REJECTED) {
        return 'projects.requestAccess.rejected'
      } else if (this.project.access.status === ProjectAccess.IN_PROGRESS) {
        return 'projects.requestAccess.sent'
      } else if (this.project.access.status === ProjectAccess.ACCEPTED) {
        return 'projects.requestAccess.accepted'
      } else if (this.project.access.status === ProjectAccess.ACCESSIBLE) {
        return 'projects.requestAccess.ok'
      }
      return 'projects.requestAccess.query'
    },
    requestDisabled() {
      return (
        this.project &&
        this.project?.access?.status !== ProjectAccess.NOT_ACCESSIBLE
      )
    },
    offlineStatus() {
      return this.project?.id
        ? this.getProjectOfflineStatus(this.project.id)
        : ''
    },
    ...mapGetters(['getProjectOfflineStatus']),
  },
  methods: {
    onClick() {
      if (!this.shouldDisplayAskAccess) {
        this.$emit('click', this.project)
      }
    },
    onImageUrlError(evt) {
      const fallback =
        this.layout === 'grid'
          ? getProjectLargeCoverUrlError()
          : getProjectCoverUrlError()
      if (evt.target.src !== fallback) evt.target.src = fallback
    },
    requestAccess(event) {
      event.stopPropagation()
      event.preventDefault()
      this.$store.dispatch('sendRequestAccess', { project: this.project })
    },
  },
})
</script>

<style scoped>
.rn-image {
  transform: scale(1);
  transition: linear 0.2s all;
}

.rn-container:hover .rn-image {
  transform: scale(1.1);
}

/* https://stackoverflow.com/questions/66484943/how-to-change-title-of-an-ion-icon */
.rn-ion-icon {
  pointer-events: none;
}
</style>
