<template>
  <el-dropdown placement="bottom-end" @command="onSelect" trigger="click">
    <div class="flex items-center text-secondary">
      <el-avatar size="large" :src="$store.getters.avatar" />
      <icon :icon="caretDownOutline" class="ml-1 text-xl" />
    </div>

    <template #dropdown>
      <el-dropdown-menu>
        <el-dropdown-item command="preferences">
          <icon :icon="settingsOutline" class="mr-3 text-xl" />
          {{ $t('userMenu.preferences') }}
        </el-dropdown-item>
        <el-dropdown-item command="diagnostic">
          <icon :icon="diagnosticIcon" class="mr-3 text-xl" />
          {{ $t('diagnostic.title') }}
        </el-dropdown-item>
        <!-- <el-dropdown-item command="help">
          <ion-icon :icon="helpCircleOutline" class="mr-3 text-xl" />
          {{ $t('userMenu.help') }}
        </el-dropdown-item> -->
        <!-- <el-dropdown-item>{{
          $t('userMenu.appsIOSAndAndroid')
        }}</el-dropdown-item> -->
        <el-dropdown-item
          command="discardOffline"
          :disable="!canDiscardOfflineData"
        >
          <icon :icon="cloudOfflineOutline" class="mr-3 text-xl" />
          <div class="flex-1">
            <div class="mb-0">{{ $t('global.offline.clearAllData') }}</div>
            <div
              v-if="storageUsage != null"
              class="
                -mt-1
                mb-1
                leading-tight
                font-medium
                text-secondary text-sm
              "
            >
              {{
                $t('global.offline.estimatedSize', {
                  sizeInMegaBytes: storageUsage,
                  layerCount: offlineLayerCount,
                })
              }}
            </div>
          </div>
        </el-dropdown-item>
        <el-dropdown-item command="logout">
          <icon :icon="logoutIcon" class="mr-3 text-xl" />
          {{ $t('global.logout') }}
        </el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<script>
import { defineComponent } from 'vue'
import {
  caretDownOutline,
  cloudOfflineOutline,
  helpCircleOutline,
  settingsOutline,
} from 'ionicons/icons'
import {
  ElDropdown,
  ElDropdownMenu,
  ElDropdownItem,
  ElAvatar,
} from 'element-plus'

import Icon from '@/components/atoms/Icon'
import { logoutIcon, diagnosticIcon } from '@/assets/icons'

export default defineComponent({
  components: {
    Icon,
    ElDropdown,
    ElDropdownMenu,
    ElDropdownItem,
    ElAvatar,
  },
  setup() {
    return {
      caretDownOutline,
      logoutIcon,
      helpCircleOutline,
      settingsOutline,
      cloudOfflineOutline,
      diagnosticIcon,
    }
  },
  data() {
    return { pollStorageInfo: 0 }
  },
  mounted() {
    this.pollStorageInfo = setInterval(
      () => this.$store.dispatch('updateStorageInfo'),
      750
    )
  },
  unmounted() {
    clearInterval(this.pollStorageInfo)
  },
  methods: {
    async onSelect(command) {
      if (command === 'logout') {
        this.$store.dispatch('logout')
      } else if (command === 'diagnostic') {
        this.$store.dispatch('startDiagnosticManually')
      } else if (command === 'discardOffline') {
        this.$store.dispatch('discardAllOfflineData')
      }
    },
  },
  computed: {
    canDiscardOfflineData() {
      return true
    },
    offlineLayerCount() {
      return this.$store.getters.countAtLeastPartiallyOfflineLayers
    },
    storageUsage() {
      return this.$store.getters.storageUsageInMegaBytes
    },
  },
})
</script>
