<template>
  <authenticated-page>
    <section-list class="h-screen flex flex-col">
      <div class="flex items-center p-2.5">
        <portal-select
          :portals="portals"
          v-model="selectedPortalId"
          v-if="portals.length > 0"
        />
        <div
          class="
            hidden
            md:flex
            items-center
            px-2.5
            flex-1
            max-w-screen-sm
            leading-0
          "
          v-if="portals.length > 0"
        >
          <projects-search-bar :projects="projects" />
        </div>
        <div class="flex-1 hidden md:block px-2.5" />
        <div class="hidden md:block">
          <user-menu />
        </div>
        <icon-button
          :icon="settingsOutline"
          class="block md:hidden ml-4"
          size="medium"
          iconClass="text-4xl"
          primary
          @click="openPreferences"
        />
      </div>
      <div class="flex-1 p-2.5 overflow-auto items-center justify-center">
        <div class="pb-2.5 md:hidden" v-if="portals.length > 0">
          <projects-search-bar :projects="projects" />
        </div>
        <div
          class="flex-1"
          :class="{
            'grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 2xl:grid-cols-8 gap-2.5':
              filteredProjects.length > 0 && projectsLayout === 'grid',
            'flex flex-col items-strech justify-start max-w-screen-md m-auto':
              filteredProjects.length > 0 && projectsLayout === 'list',
            'flex flex-col items-center justify-center':
              filteredProjects.length === 0 && projectsLayout === 'grid',
          }"
          v-if="filteredProjects.length > 0"
        >
          <project-preview
            v-for="project in filteredProjects"
            :key="project.id"
            :project="project"
            :layout="projectsLayout"
            @click="select(project)"
          />
        </div>
        <div
          class="
            flex-1 flex flex-col
            items-center
            justify-center
            text-secondary-700
          "
          v-if="filteredProjects.length === 0"
        >
          <loading-spinner secondary v-if="!areFetched" />
          <warning-message
            :title="$t('global.noPortals')"
            :description="$t('global.askYourAdmin')"
            v-if="areFetched && portals.length === 0"
          />
          <warning-message
            :title="$t('projects.noProjects')"
            :description="$t('projects.addProjects')"
            v-if="areFetched && portals.length > 0 && projects.length === 0"
          />
          <warning-message
            :title="$t('projects.noResultFound')"
            :description="$t('projects.tryToShortenQuery')"
            v-if="
              areFetched &&
              portals.length > 0 &&
              projects.length > 0 &&
              filteredProjects.length === 0
            "
          />
        </div>
      </div>
    </section-list>
  </authenticated-page>
</template>

<script>
import { defineComponent } from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import {
  settingsOutline,
  searchOutline,
  optionsOutline,
  closeCircleOutline,
  checkmarkOutline,
  gridOutline,
  listOutline,
} from 'ionicons/icons'

import AuthenticatedPage from '@/components/templates/AuthenticatedPage.vue'
import UserMenu from '@/components/organisms/UserMenu.vue'
import PortalSelect from '@/components/organisms/PortalSelect.vue'
import ProjectPreview from '@/components/organisms/ProjectPreview.vue'
import ProjectsSearchBar from '@/components/organisms/ProjectsSearchBar'
import IconButton from '@/components/atoms/IconButton'
import SectionList from '@/components/atoms/SectionList'
import LoadingSpinner from '@/components/atoms/LoadingSpinner'
import WarningMessage from '@/components/atoms/WarningMessage'

import { findRoute } from '@/plugins/router'
import { search } from '@/utils/project'

function selectBestPortal(newPortals) {
  if (newPortals.length > 0) {
    selectPortalBasedOnRoute.bind(this)(this.$route)
    if (this.selectedPortalId === null || this.selectedPortalId === undefined) {
      this.selectedPortalId = newPortals[0].id
    }
  }
}

function selectPortalBasedOnRoute(to) {
  const slug = to.params?.portalSlug || null
  if (slug) {
    const portal = this.portals.find(portal => portal.slug === slug)

    this.selectedPortalId = portal?.id
  }
}

const transform = portal => ({ value: portal.id, label: portal.name })

export default defineComponent({
  name: 'Projects',
  components: {
    AuthenticatedPage,
    UserMenu,
    PortalSelect,
    ProjectPreview,
    ProjectsSearchBar,
    IconButton,
    SectionList,
    LoadingSpinner,
    WarningMessage,
  },
  setup() {
    return {
      settingsOutline,
      searchOutline,
      optionsOutline,
      closeCircleOutline,
      checkmarkOutline,
      layoutOptions: [
        { value: 'grid', label: 'projects.layout.grid', icon: gridOutline },
        { value: 'list', label: 'projects.layout.list', icon: listOutline },
      ],
    }
  },
  data() {
    return {
      selectedPortalId: null,
      filteredProjects: [],
      listSettingsOpen: false,
    }
  },
  computed: {
    projects() {
      return this.getProjectsByPortalId(this.selectedPortalId)
    },
    areFetched() {
      return (
        this.$store.getters.arePortalsFetched &&
        this.$store.getters.areProjectsFetched(this.selectedPortalId)
      )
    },
    ...mapGetters([
      'currentUser',
      'portals',
      'projectsLayout',
      'query',
      'getProjectsByPortalId',
      'areProjectsFetched',
    ]),
  },
  async mounted() {
    selectBestPortal.bind(this)(this.portals)
    this.updateFilteredProjects()
  },
  methods: {
    transform,
    openPreferences() {
      this.$router.push(findRoute('preferences'))
    },
    updateFilteredProjects() {
      this.filteredProjects = search(this.projects, this.query)
    },
    select(project) {
      this.$router.push(findRoute('project', { projectId: project?.id }))
    },
    ...mapActions(['fetchProjectsOfPortal']),
    ...mapMutations(['setProjectsLayout']),
  },
  watch: {
    portals: selectBestPortal,
    selectedPortalId(portalId) {
      if (portalId) {
        const portal = this.portals.find(portal => portal.id === portalId)
        this.$router.replace(`/${portal.slug}/projects`)
        this.fetchProjectsOfPortal({ portalId })
      }
    },
    projects() {
      this.updateFilteredProjects()
    },
    query() {
      this.updateFilteredProjects()
    },
    $route: selectPortalBasedOnRoute, // in case the route change, (using back button for example)
  },
})
</script>
